<template>
  <div class="home bg-gradient-primary">
    <div class="container">

        <!-- Outer Row -->
        <div class="row justify-content-center align-items-center" style="min-height:100vh;">

            <div class="col-xl-10 col-lg-12 col-md-9">

                <div class="card o-hidden border-0 shadow-lg my-5 text-center">
                    <div class="card-body p-0">
                        <img src="/img/Nanosense-Logo.png" width="300px" alt="" style="margin:30px">
                    </div>
                </div>

            </div>

        </div>

    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import Swal from 'sweetalert2'

export default {
  name: 'Home',
  props: ['url'],
  data: () => {
      return {
          username: "",
          password: ""
      }
  },
  methods: {
      login() {
          let obj = {
              username: this.username,
              password: this.password
          }
          axios.post(`${this.url}/user/signin`, obj)
            .then((response) => {
                localStorage.setItem('token_monitor', response.headers['x-auth-login-token'])
                this.$router.push("/dashboard")
            })
            .catch((error) => {
                Swal.fire('Wrong Password/Username !', '', 'error')
                console.log(error.message)
            })
      }
  }
}
</script>
