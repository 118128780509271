<template>
  <div class="home">
    <div id="wrapper">
      <div id="content-wrapper" class="d-flex flex-column">
        <div id="content">
          <div class="container-fluid" style="min-height:100vh; padding: 20vh;">
            <div class="text-center">
                <div class="error mx-auto" data-text="404">404</div>
                <p class="lead text-gray-800 mb-5">Page Not Found</p>
                <p class="text-gray-500 mb-0">It looks like you found a glitch in the matrix...</p>
                <router-link to="/dashboard-calibrator">&larr; Back to Dashboard</router-link>
                <p class="text-gray-500 mb-0" style="padding-top: 50px; font-size: 14px;">Copyright 2021 Genose C19. All rights reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'NotFound',
}
</script>
