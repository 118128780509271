<template>
  <div class="home">
    <div id="wrapper">
      <div id="content-wrapper" class="d-flex flex-column">
        <div id="content">
          <div class="container-fluid" style="min-height:100vh;">
            <div class="text-center">
                <img src="/img/nanosense-logo-RGB 800x72.png" width="250px" alt="" style="margin:30px">
                <br>
                <br>
                <img v-if="userInfo.gender == `male`" src="/img/undraw_profile_2.svg" width="200px" alt="Profile">
                <img v-if="userInfo.gender == `female`" src="/img/undraw_profile_3.svg" width="200px" alt="Profile">
                <br>
                <br>
                <p class="lead text-gray-800">
                {{userInfo.name}}
                </p>
                <br>
                <h4 class="text-gray-800">
                <b>{{userInfo.position}}</b>
                </h4>
                <h5 class="text-gray-800">
                {{userInfo.level}}
                </h5>
                <br>
                <p class="text-gray-800">
                Division : <b>{{userInfo.division}}</b>
                </p>
                <p class="text-gray-500 mb-0" style="padding-top: 20px; font-size: 14px;">Copyright 2021 Nanosense Instrument Indonesia. All rights reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'


export default {
  name: 'Profile',
  props: ['url'],
  data: () => {
      return {
          userInfo: {},
      }
  },
  methods : {
      getUserInfo() {
        let config = {
            method: 'GET',
            baseURL: `${this.url}/team/member/${this.$route.params.id}`,
        }
        axios(config)
        .then((response) => {
            this.userInfo = response.data
        })
        .catch((error) => {
            console.log(error.message)
        })
      },
  },
  created() {
    this.getUserInfo()
  }
}
</script>
